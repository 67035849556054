// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_rS d_fp d_bG d_dv";
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignDiscCenter = "t_rT d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_rV d_fr d_bH d_dx";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var footerContainer = "t_rW d_dW d_bW";
export var footerContainerFull = "t_rX d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_rY d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_rZ d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_r0 d_bz d_bJ d_bN d_bL";
export var wide = "t_r1 d_cy";
export var right = "t_r2 d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_r3 d_bC d_bP d_bJ";
export var badgeContainer = "t_r4 d_bz d_bH d_bP";
export var badge = "t_r5";
export var padding = "t_r6 d_c7";
export var end = "t_r7 d_bH";
export var linkWrapper = "t_r8 d_dB";
export var link = "t_mC d_dB";
export var colWrapper = "t_r9 d_cx";
export var consent = "t_f d_f d_bC d_bP";
export var disclaimer = "t_sb d_bz d_bJ";
export var media = "t_sc d_bx d_dy";
export var itemRight = "t_sd";
export var itemLeft = "t_sf";
export var itemCenter = "t_sg";
export var exceptionWeight = "t_sh C_tp";