// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sr d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_ss d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_st d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_sv d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_sw d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_sx d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_sy d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_sz C_sz";
export var heroExceptionNormal = "w_sB C_sB";
export var heroExceptionLarge = "w_sC C_sC";
export var Title1Small = "w_sD C_sD C_sY C_sZ";
export var Title1Normal = "w_sF C_sF C_sY C_s0";
export var Title1Large = "w_sG C_sG C_sY C_s1";
export var BodySmall = "w_sH C_sH C_sY C_tg";
export var BodyNormal = "w_sJ C_sJ C_sY C_th";
export var BodyLarge = "w_sK C_sK C_sY C_tj";