// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "r_q6 d_bD";
export var datasheetRowWrapper = "r_lt d_lt d_bK";
export var datasheetLeftWrapper = "r_q7 d_bz d_bP";
export var datasheetWrapperFull = "r_q8 d_cD";
export var datasheetWrapperFullLeft = "r_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "r_mv d_lv";
export var contentWrapperCenter = "r_q9 d_lz";
export var datasheetLeftWrapperCenter = "r_rb d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "r_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "r_rc d_lw";
export var datasheetFooter = "r_ly d_ly";
export var alignLeft = "r_qh d_dv";
export var alignCenter = "r_bP d_dw";
export var alignRight = "r_qj d_dx";
export var datasheetInnerWrapperNewLeft = "r_rd d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "r_rf d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "r_rg d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "r_rh d_bK";
export var datasheetSubtitle = "r_rj d_cx";
export var tableRow = "r_rk";
export var cell = "r_rl";
export var tableRowWrapper = "r_rm d_w d_dv";
export var tableHeadingCell = "r_rn";
export var tableHeading = "r_rp";
export var tableRowStriped = "r_rq";
export var tableRowHeading = "r_rr";
export var dataTable = "r_rs d_w";
export var imageWrapper = "r_rt d_fg d_Z";
export var imageWrapperFull = "r_rv d_H d_w d_bf d_Z";
export var imageWrapperIcon = "r_rw";
export var titleMargin = "r_rx d_cs";
export var datasheetInnerInnerWrapper = "r_ry d_w";
export var hasLabels = "r_rz";
export var label = "r_rB";
export var SmallSmall = "r_rC C_rC C_sY C_tk";
export var SmallNormal = "r_rD C_rD C_sY C_tl";
export var SmallLarge = "r_rF C_rF C_sY C_th";