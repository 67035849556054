// extracted by mini-css-extract-plugin
export var noPadding = "y_fj d_fj";
export var videoFrameWrapper = "y_sN d_kS d_b5";
export var videoIframeStyle = "y_pS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "y_sP d_kR d_bK";
export var videoInnerWrapper = "y_sQ d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "y_sR d_dw d_w";
export var videoInnerInnerWrapper = "y_sS d_bz d_bD d_bJ";
export var btnWrapper = "y_d2 d_w d_bz d_bD d_cj";
export var btn = "y_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n C_tF C_tr";
export var icon = "y_p9 d_cC";
export var textLeft = "y_dv";
export var textCenter = "y_dw";
export var textRight = "y_dx";